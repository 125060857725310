<template>
  <div class="question-component">
    <div class="progress">
      <p class="progress-text">
        {{ currentIndex + 1 }} / {{ numberOfQuestions }}
      </p>
      <div class="progress-bar-container">
        <div class="progress-bar"></div>
        <div
          class="progress-bar-fill"
          :style="{'width': `${(currentIndex + 1) / numberOfQuestions * 100}%`}"
        ></div>
      </div>
      <button
        @click="setCurrentComponent('DialogChooser')"
        class="progress-stop"
      ></button>
    </div>
    <div
      class="dialog-container"
      ref="container"
      v-if="questionData"
    >
      <div
        class="avatar-bubble"
      >
        <div>
          {{ questionData.question.text }}
        </div>
        <div class="avatar-bubble-button">
          <button
            v-show="['askQuestion', 'waitAnswer'].includes(state)"
            class="volume-button volume-button--light"
            @click="replayQuestion()"
          >
          </button>
        </div>
      </div>

      <div
        class="avatar-icon"
        ref="avatarIcon"
      >
        <div
          class="avatar-question"
          ref="avatarQuestion"
          v-show="['askQuestion', 'waitAnswer', 'recording', 'serverWait'].includes(state)"
        ></div>
      </div>

      <div
        class="big-bubble"
        ref="bigBubble"
        v-show="['serverWait', 'serverNotUnderstand'].includes(state)"
      >
        <div
          class="loading-animation"
          v-show="state === 'serverWait'"
          ref="loadingAnimation"
        ></div>
        <div
          class="not-understand"
          v-show="state === 'serverNotUnderstand'"
        >
          <div>?</div>
          <button
            class="volume-button"
            @click="playBlob()"
          >
          </button>
        </div>
      </div>

      <div
        class="flex-spacer"
        v-show="['askQuestion', 'waitAnswer', 'recording', 'serverWait'].includes(state)"
      ></div>

      <div
        class="onboarding-answers"
        :class="{'app-display-none': !showOnboarding}"
        ref="onboardingAnswers"
        v-show="questionData && ['askQuestion', 'waitAnswer', 'recording',].includes(state)"
      >
        <img
          class="onboarding-answers-image"
          alt="lightbulb-icon"
          src="/assets/icons/lightbulb.svg"
        />
        <div>
          Wähle eine der beiden Antworten:
        </div>
      </div>

      <div
        class="answers"
        ref="answers"
        v-show="questionData && ['askQuestion', 'waitAnswer', 'recording', 'serverUnderstand'].includes(state)"
      >
        <div
          v-for="(answer, index) in questionData.answers"
          :key="index"
          :class="{
            'server-correct': answer.text === serverAnswerContent && state === 'serverUnderstand',
            'server-wrong': answer.text !== serverAnswerContent && state === 'serverUnderstand',
          }"
        >
          <div
            class="answer"
            :class="{'answer--first': ['askQuestion', 'waitAnswer', 'recording'].includes(state) && index === 0}"
          >
            <div class="answer-text">
              <div>{{ answer.text }}</div>
              <div class="answer-text-buttons">
                <button
                  v-show="['askQuestion', 'waitAnswer'].includes(state)"
                  class="volume-button"
                  @click="playAnswer(index)"
                >
                </button>
                <button
                  v-show="false"
                  class="volume-button"
                  @click="playBlob()"
                >
                </button>
                <img
                  src="/assets/icons/correct.svg"
                  v-show="answer.text === serverAnswerContent && state === 'serverUnderstand'"
                >
              </div>
            </div>
          </div>

          <div
            v-show="['askQuestion', 'waitAnswer', 'recording', ].includes(state) && index === 0"
            class="answer-or"
          >
            oder
          </div>
        </div>
      </div>

      <div
        class="avatar-bubble avatar-bubble-answer"
        ref="avatarBubbleAnswer"
        v-show="['serverUnderstand', 'serverNotUnderstand'].includes(state)"
      >
        {{ responseText }}
      </div>

      <div
        class="avatar-icon avatar-icon-answer"
        ref="avatarIconAnswer"
      >
        <div
          class="avatar-response"
          ref="avatarResponse"
          v-show="['serverUnderstand', 'serverNotUnderstand'].includes(state)"
        ></div>
        <div class="flex-spacer"></div>
      </div>


      <div
        class="flex-spacer"
        v-show="['serverUnderstand', 'serverNotUnderstand'].includes(state)"
      ></div>

      <div
        class="onboarding-record"
        :class="{'app-display-none': !showOnboarding}"
        ref="onboardingRecord"
        v-show="['askQuestion', 'waitAnswer', 'recording'].includes(state)"
      >
        <img
          class="onboarding-answers-image"
          alt="lightbulb-icon"
          src="/assets/icons/lightbulb.svg"
        />
        <div>
          Drücke und halte den Aufnahme-Button (<img
            class="onboarding-record-microphone"
            src="/assets/icons/microphone.svg"
          />).
          Sprich eine der beiden Antworten nach.
        </div>
      </div>

      <div
        class="record-section"
        ref="recordSection"
        v-show="['askQuestion', 'waitAnswer', 'recording'].includes(state)"
      >
        <div
          :class="{'record-text': true, 'record-text--active': state === 'recording'}"
        >
          <div v-if="state === 'recording'">
            Aufnahme läuft
          </div>
          <div v-else>
          </div>
        </div>
        <div
          class="record-button"
          ref="recordButton"
          @pointerdown.stop.prevent="recordDown()"
          @pointerup="recordUp()"
          @touchstart.stop.prevent="absorbEvent()"
          @touchend.stop.prevent="absorbEvent()"
          @touchmove.stop.prevent="absorbEvent()"
          @touchcancel.stop.prevent="absorbEvent()"
        ></div>
      </div>

      <div
        class="continue-section"
        ref="continueSection"
        v-show="['serverUnderstand', 'serverNotUnderstand'].includes(state)"
      >
        <div
          class="app-continue-button"
          v-show="state === 'serverUnderstand'"
          @click="nextQuestion('Verstanden')"
        >
          Weiter geht's!
        </div>

        <div v-show="state === 'serverNotUnderstand'">
          <div
            class="app-continue-button"
            @click="retryQuestion()"
          >
            Nochmals versuchen
          </div>
          <div
            class="skip-button app-link"
            @click="nextQuestion('Übersprungen')"
          >
            Überspringen
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as log from 'loglevel';

import axios from 'axios';
import { Howl } from 'howler';
import 'velocity-animate/velocity.ui.min.js';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import data from '@/data';
import Velocity from 'velocity-animate';
import { trackEvent } from '@/matomoClient';

export default {
  name: 'Question',
  // eslint-disable-next-line vue/require-prop-types
  props: ['id', 'numberOfQuestions', 'currentIndex'],
  emits: ['nextQuestion'],
  data: function () {
    return {
      rec: null,
      audioBlob: null,
      audioUrl: null,
      audioFilename: null,
      apiResponse: '',

      questionData: null,

      recordStartTime: null,

      // state in ['askQuestion', 'waitAnswer', 'recording', 'serverWait', 'serverUnderstand', 'serverNotUnderstand']
      state: 'askQuestion',
      serverAnswerContent: '',
      serverAnswerIndex: null,
      responseText: '',
      numberAttempts: 0,
    }
  },
  computed: {
    ...mapState(['audioInput', 'audioInputDeviceId', 'audioInputDeviceLabel', 'audioFirstRecording', 'showOnboarding']),
    ...mapGetters(['audioAvailable']),
  },
  created() {
    this.$watch(
        () => this.id,
        (newValue) => {
          log.debug('id changed', newValue);
          this.numberAttempts = 0;
          this.startQuestion();
        }
    )
  },
  mounted() {
    log.debug("Question mounted", this.id);
    this.questionData = data.questions[this.id];

    this.openMicrophone().then(() => {
      this.avatarQuestionAnimation = window.lottie.loadAnimation({
        container: this.$refs.avatarQuestion,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: '/assets/animations/avatar-all-animations.json'
      })
      this.avatarQuestionAnimation.addEventListener('DOMLoaded', () => {
        setTimeout(() => {
          this.startQuestion();
        }, 500);
      });

      this.avatarResponseAnimation = window.lottie.loadAnimation({
        container: this.$refs.avatarResponse,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: '/assets/animations/avatar-all-animations.json'
      });

      this.recordButtonAnimation = window.lottie.loadAnimation({
        container: this.$refs.recordButton,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: '/assets/animations/speak-button.json'
      });

      this.loadingAnimation = window.lottie.loadAnimation({
        container: this.$refs.loadingAnimation,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: '/assets/animations/loading.json'
      });

      this.notUnderstandSound = new Howl({
        src: ['/assets/audio/pas-compris.mp3']
      });
    });
  },
  methods: {
    ...mapActions(["openMicrophone"]),
    ...mapMutations([
        'setCurrentComponent', 'setAudioFirstRecording', 'incrementDialogNumberAttempts', 'setShowOnboarding'
    ]),
    startQuestion() {
      this.incrementDialogNumberAttempts();
      this.numberAttempts += 1;
      this.serverAnswerIndex = null;

      trackEvent('Question', 'Frage starten', this.id, this.numberAttempts);

      this.questionData = data.questions[this.id];
      this.state = 'askQuestion';
      this.serverAnswerContent = '';

      Velocity([
        this.$refs.avatarIcon
      ], {
        opacity: 1,
      }, {
        duration: 0,
        delay: 0,
      });

      this.questionSound = new Howl({
        src: [this.questionData.question.audioSrc]
      });
      this.answer0Sound = new Howl({
        src: [this.questionData.answers[0].textAudioSrc]
      });
      this.answer1Sound = new Howl({
        src: [this.questionData.answers[1].textAudioSrc]
      });
      this.response0Sound = new Howl({
        src: [this.questionData.answers[0].responseAudioSrc]
      });
      this.response1Sound = new Howl({
        src: [this.questionData.answers[1].responseAudioSrc]
      });

      this.recordButtonAnimation.playSegments([1, 2], true);

      Velocity([
        this.$refs.recordSection,
        this.$refs.answers,
        this.$refs.onboardingAnswers,
        this.$refs.onboardingRecord,
      ], {
        'opacity': 0,
      }, {
        duration: 0,
        delay: 0,
      });

      this.recordButtonAnimation.playSegments([1, 2], true);

      const showRecordButton = () => {
        Velocity(this.$refs.recordSection, {
          'opacity': 1,
        }, {
          duration: 1000,
          easing: "swing",
          loop: false,
          delay: 500,
          complete: () => {
            this.recordButtonAnimation.playSegments([3, 25], true);
            this.state = 'waitAnswer';
            this.scrollToBottom();
          }
        });
      }

      const showAnswers = () => {
        Velocity([this.$refs.answers], {
          'opacity': 1,
        }, {
          duration: 500,
          easing: "swing",
          loop: false,
          delay: 0,
          complete: () => {
            if(this.showOnboarding) {
              Velocity(this.$refs.onboardingRecord, {
                opacity: 1,
              }, {
                duration: 500,
                delay: 1000,
                complete: () => {
                  setTimeout(() => {
                    showRecordButton();
                  }, 500);
                }
              });
            } else {
              showRecordButton();
            }
          }
        });
      };

      Velocity(this.$refs.container, {
        opacity: 1,
      }, {
        duration: 500,
        delay: 0,
        complete: () => {
          this.questionSound.play();
          this.avatarQuestionAnimation.loop = true;
          this.avatarQuestionAnimation.playSegments([2, 15], true);
          this.questionSound.on('end', () => {
            this.avatarQuestionAnimation.stop();

            if (this.showOnboarding) {
              Velocity(this.$refs.onboardingAnswers, {
                opacity: 1,
              }, {
                duration: 500,
                delay: 500,
                complete: () => {
                  setTimeout(() => {
                    showAnswers();
                  }, 1000);
                }
              });
            } else {
              showAnswers();
            }
          });
        }
      });
    },

    retryQuestion() {
      // trackEvent('Navigation', 'Frage nochmal versuchen', this.id);
      Velocity(this.$refs.container, {
        opacity: 0,
      }, {
        duration: 500,
        delay: 0,
        complete: () => {
          this.startQuestion();
        }
      })
    },

    replayQuestion() {
      trackEvent('Question', 'Frage erneut abspielen', this.id);
      this.questionSound.play();
      this.avatarQuestionAnimation.loop = true;
      this.avatarQuestionAnimation.playSegments([2, 15], true);
      this.questionSound.on('end', () => {
        this.avatarQuestionAnimation.stop();
      });
    },

    playAnswer(index) {
      trackEvent('Question', 'Antwort erneut abspielen', `${this.id}-${index}`);
      this[`answer${index}Sound`].play();
    },

    playBlob() {
      const audio = new Audio(this.audioUrl);
      trackEvent('Question', 'Aufnahme abspielen', this.id);
      audio.play();
    },

    // eslint-disable-next-line no-unused-vars
    nextQuestion(clickType) {
      log.debug('emit "nextQuestion"', clickType);
      let goalTrackText = `Antwort ${this.id} ${clickType}`;
      if (this.serverAnswerIndex) {
        goalTrackText += ` ${this.serverAnswerIndex + 1}`;
      }
      trackEvent('GoalTracking', goalTrackText);
      if (clickType === 'Übersprungen') {
        trackEvent('Question', 'Übersprungen', this.id);
      }
      this.setShowOnboarding(false);
      // trackEvent('Navigation', `Link Click ${clickType}`, this.id);
      Velocity(this.$refs.container, {
        opacity: 0,
      }, {
        duration: 500,
        delay: 0,
        complete: () => {
          this.$emit('nextQuestion');
        }
      })
    },

    recordDown() {
      log.debug('record down');

      this.recordStartTime = new Date();

      if (this.audioAvailable && this.state === 'waitAnswer') {
        this.state = 'recording';
        this.serverAnswerContent = '';
        this.rec = new window.Recorder(this.audioInput, {numChannels: 1})
        this.rec.record()
        log.debug("Recording started")
        this.recordButtonAnimation.playSegments([26, 82], true)
      }
    },

    recordUp() {
      log.debug('record up')

      if (this.audioAvailable && this.state === 'recording') {

        const recordTime = (new Date()) - this.recordStartTime;
        log.debug("recordTime", recordTime);

        if (recordTime < 1000) {
          log.debug("recordTime too short go back to wait" , recordTime);
          this.recordButtonAnimation.playSegments([3, 25], true);
          this.rec.stop();
          this.rec = null;
          this.state = 'waitAnswer';
        } else {
          trackEvent('Question', 'Aufnahme gemacht', this.id, recordTime);

          Velocity([
              this.$refs.answers,
              this.$refs.onboardingAnswers,
              this.$refs.onboardingRecord,
              this.$refs.recordSection,
          ], {
            'opacity': 0,
          }, {
            duration: 500,
            easing: "swing",
            loop: false,
            delay: 0,
            complete: () => {
              this.state = 'serverWait';
              this.recordButtonAnimation.playSegments([1, 2], true);

              Velocity(this.$refs.bigBubble, {
                'opacity': 1,
              }, {
                duration: 500,
                easing: "swing",
                loop: false,
                delay: 0,
                complete: () => {
                  setTimeout(() => {
                    //tell the recorder to stop the recording and continue
                    this.setAudioFirstRecording(false);
                    this.rec.stop()
                    this.rec.exportWAV(this.createAudioUrl);
                    this.rec = null;
                  }, this.audioFirstRecording ? 1500 : 1000);
                }
              });
            }
          });
        }
      }
    },

    createAudioUrl(blob) {
      log.debug('createAudioUrl', blob);
      this.audioBlob = blob;
      this.audioUrl = URL.createObjectURL(blob);
      this.audioFilename = new Date().toISOString() + ".wav";

      this.uploadToApi(blob);
    },

    uploadToApi(blob) {

      const formData = new FormData();
      formData.append('execution', 'sync');
      formData.append('language', 'fra-CH');
      formData.append('modelId', 'latest');
      formData.append('alternatives', JSON.stringify(this.questionData.answers.map((a) => {
        return {
          content: a.text,
          uuid: a.uuid,
        };
      })));
      formData.append('inputDeviceId', this.audioInputDeviceId);
      formData.append('inputDeviceLabel', this.audioInputDeviceLabel);
      formData.append('userAgent', navigator.userAgent);

      formData.append('dataFile', blob);

      // JWT Token per Email von david@recapp.ch via jonathan.sejnoha@schulverlag.ch erhalten, gültig bis 2024-06-30T23:59:59Z
      const jwtToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJlZHNvbHV0aW9uIiwibmFtZSI6ImVkc29sdXRpb24iLCJpYXQiOjE3MDU2NzA0NjEsImV4cCI6MTcxOTc5MjAwMH0.c6nqEv-RChrV18_CZ9susOfQ4RVSIhUwusIEfJMpo6_1L4nTdE0eg71kncAte6WpCD2MX2rNHJ6Bs44vew5-cWS_rbk6qQfgIng9Bpu9sQ-yWxzaCcwk9BxhQ3ENpQNJrvG8ZJl7U9I2lmJnbrj7KzcQqIeLxVGobeuNdbOks2dVz3L0hG28kEpXvVi8kf81eePsp3AMcZHoVfKct-sktaGuA4yJp_eCUdqVk5Yb8jmbEyupGESH1tKEeiHCc31OwynfdNYFOeUerhIrRD2twMd1HKbdh7f_Fy7o-wMePJkaQlKRuf6oCm1tIfgLjBk3CUXiYsbqskGRJqPo85dyeg';

      let apiUrl = 'https://edsolution.recapp.ch/scorings/';

      // if (window.location.hostname === 'sprachassistent.iterativ.ch') {
      //   apiUrl = '/scorings/'
      // }

      const apiStartTime = new Date();
      return axios({
        // keep the `/` at the end of `/scorings/` otherwise Safari will return a 403 (because of a redirect?)
        url: apiUrl,
        method: 'POST',
        data: formData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${jwtToken}`,
        },
      }).then((response) => {
        log.debug(response);

        const apiTime = (new Date()) - apiStartTime;
        this.apiResponse = response.data;

        // initial hide
        Velocity([
          this.$refs.avatarBubbleAnswer,
          this.$refs.avatarIconAnswer,
          this.$refs.continueSection,
        ], { opacity: 0, }, { duration: 0, });
        Velocity(this.$refs.avatarIcon, { opacity: 0, }, { duration: 500, easing: 'swing', });

        Velocity(this.$refs.bigBubble, {
          'opacity': 0,
        }, {
          duration: 500,
          easing: "swing",
          loop: false,
          delay: 0,
          complete: () => {
            if(response.data.matches.length > 0 && response.data.state === 'done') {
              this.state = 'serverUnderstand';
              trackEvent('Question', 'Server verstanden', this.id, apiTime);
              this.serverAnswerContent = response.data.matches[0].content;
              this.serverAnswerIndex = this.serverAnswerContent === this.questionData.answers[1].text ? 1 : 0;
              this.responseText = this.questionData.answers[this.serverAnswerIndex].response;

            } else {
              this.state = 'serverNotUnderstand';
              this.responseText = 'Désolé, je n’ai pas compris.'
              trackEvent('Question', 'Server nicht verstanden', this.id, apiTime);
            }

            Velocity([
              this.$refs.bigBubble,
              this.$refs.answers,
            ], {
              opacity: 1,
            }, {
              duration: 500,
              easing: "swing",
              loop: false,
              delay: 0,
              complete: () => {
                // show relevant stuff
                Velocity([
                  this.$refs.avatarBubbleAnswer,
                  this.$refs.avatarIconAnswer,
                  this.$refs.continueSection,
                ], {
                  opacity: 1,
                }, {
                  duration: 500,
                  easing: 'swing',
                  delay: 1000,
                  complete: () => {
                    if(response.data.matches.length > 0 && response.data.state === 'done') {
                      this.state = 'serverUnderstand';

                      setTimeout(() => {
                        this[`response${this.serverAnswerIndex}Sound`].play();
                        this.avatarResponseAnimation.loop = true;
                        this.avatarResponseAnimation.playSegments([2, 15], true);
                        this[`response${this.serverAnswerIndex}Sound`].on('end', () => {
                          this.avatarResponseAnimation.stop();
                          this.avatarResponseAnimation.loop = false;
                          this.avatarResponseAnimation.playSegments([16, 58], true);
                        });
                        this.scrollToBottom();
                      }, 500);

                    } else {
                      this.state = 'serverNotUnderstand';

                      setTimeout(() => {
                        this.notUnderstandSound.play();
                        this.scrollToBottom();
                        this.avatarResponseAnimation.loop = true;
                        this.avatarResponseAnimation.playSegments([2, 15], true);

                        this.notUnderstandSound.on('end', () => {
                          this.avatarResponseAnimation.stop();
                          this.avatarResponseAnimation.loop = false;
                          this.avatarResponseAnimation.playSegments([59, 148], true);
                        })
                      }, 500);

                    }

                  }
                });

              }
            });

          }
        });
      }).catch((error) => {
        log.warn(error);
        this.state = 'serverNotUnderstand';

        // show relevant stuff
        Velocity([
          this.$refs.bigBubble,
          this.$refs.answers,
          this.$refs.avatarBubbleAnswer,
          this.$refs.avatarIconAnswer,
          this.$refs.continueSection,
        ], {
          opacity: 1,
        }, {
          duration: 500,
          easing: 'swing',
        });

        trackEvent('Question', 'Server Fehler', this.id, (new Date()) - apiStartTime);

        setTimeout(() => {
          this.notUnderstandSound.play();
          this.scrollToBottom();
          this.avatarResponseAnimation.loop = true;
          this.avatarResponseAnimation.playSegments([2, 15], true);

          this.notUnderstandSound.on('end', () => {
            this.avatarResponseAnimation.stop();
            this.avatarResponseAnimation.loop = false;
            this.avatarResponseAnimation.playSegments([59, 148], true);
          })
        }, 500);

        this.responseText = `Server Error: ${error.message}`;
        this.apiResponse = error.message;
      });
    },

    scrollToBottom () {
      // window.scrollTo({left: 0,top: document.body.scrollHeight, behavior: 'smooth'});
    },

    preventTapEvents () {
      this.$refs.recordButton.addEventListener("touchstart", this.absorbEvent);
      this.$refs.recordButton.addEventListener("touchend", this.absorbEvent);
      this.$refs.recordButton.addEventListener("touchmove", this.absorbEvent);
      this.$refs.recordButton.addEventListener("touchcancel", this.absorbEvent);
    },

    removeTapEventListeners () {
      this.$refs.recordButton.removeEventListener("touchstart", this.absorbEvent);
      this.$refs.recordButton.removeEventListener("touchend", this.absorbEvent);
      this.$refs.recordButton.removeEventListener("touchmove", this.absorbEvent);
      this.$refs.recordButton.removeEventListener("touchcancel", this.absorbEvent);
    },

    absorbEvent (event) {
      log.debug('absorbeEvent', event);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "../assets/css/colors";
@import "../assets/css/variables";


.question-component {
  padding: $margin-small;
  width: 100%;
  color: $black;

  @include desktop {
    padding: $margin-big;
  }
}

.dialog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.avatar-bubble {
  background: $ed-blue;
  width: calc(100%);
  padding: 16px;
  color: white;
  align-self: flex-start;
  border-radius: 8px;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  line-height: 24px;

  &:after {
    content: "";
    bottom: -24px;
    left: 0;
    position: absolute;
    border-right: 32px solid transparent;
    border-top: 32px solid $ed-blue;
  }

  &-button {
    min-width: 32px;
    height: 24px;
  }
}

.avatar-icon {
  .avatar-question, .avatar-response {
    height: 100px;
    width: 100px;
    align-self: flex-start;
  }

  align-self: flex-start;
  margin-bottom: 16px;
  display: flex;
  margin-left: -16px;
}

.big-bubble {
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #284273;
  margin-bottom: 48px;
  margin-top: 32px;
  position: relative;
  background: $blue-gray;
  opacity: 0;

  &:after {
    content: "";
    bottom: -24px;
    right: 0;
    position: absolute;
    border-left: 32px solid transparent;
    border-top: 32px solid $blue-gray;
  }

  .loading-animation {
    width: 120px;
    margin: 64px;
  }

  .not-understand {
    font-size: 40px;
    margin: 32px;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.flex-spacer {
  flex: 1 1 auto;
}

.server-wrong {
  display: none;
}

.onboarding-answers {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;

  &-image {
    margin-right: 8px;
  }
}

.onboarding-record {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  line-height: 1.2;
  margin-top: -16px;

  &-image {
    margin-right: 8px;
  }

  &-microphone {
    margin: 0 4px;
    height: 14px;
    line-height: 30px;
  }
}


.answers {
  // border: 1px solid $gray-border;
  width: 100%;

  .answer-or {
    font-size: 14px;
  }

  .answer {
    border-radius: 8px 8px 0 8px;
    margin-top: 8px;
    margin-bottom: 48px;
    background: $blue-gray;

    padding: 16px;
    position: relative;

    &:after {
      content: "";
      bottom: -24px;
      right: 0;
      position: absolute;
      border-left: 32px solid transparent;
      border-top: 32px solid $blue-gray;
    }

    &--first {
      margin-bottom: 8px;
    }

    .answer-text {
      color: $blue-dark;
      line-height: 24px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .answer-text-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-width: 36px;
    }
  }
}

.record-section {
  $button-height: 122px;
  align-self: flex-end;
  opacity: 0;
  display: flex;
  align-content: center;
  margin-right: -$margin-small;

  .record-button {
    width: $button-height;
    height: $button-height;
    margin-left: auto;
  }

  .record-text {
    color: #000;
    line-height: $button-height;

    &--active div {
      margin-right: 14px;
      background: linear-gradient(to right, #000 20%, $gray-border 50%, #000 80%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
      background-size: 200% auto;
      animation: textShine 3s ease-in-out infinite;
    }
  }
}

@keyframes textShine {
  to {
    background-position: 200%;
  }
}

.continue-section {
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
}

.volume-button {
  border: 0;
  padding: 0;
  background-color: transparent !important;
  box-shadow: none !important;
  flex-shrink: 0;
  opacity: 0.3;

  &--light {
    filter: invert(100%);
    opacity: 0.5;
  }

  &:focus, &:hover {
    outline: 0;
    background-color: transparent !important;
  }

  height: 20px;
  width: 20px;
  background-image: url("/assets/icons/volume.svg");
  cursor: pointer;
  margin-left: 10px;
}

.skip-button {
  margin-top: $margin-small;
  @include desktop {
    margin-top: $margin-big;
  }
}

.progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  line-height: $margin-small;
  color: $gray-border;
  margin-bottom: 15px;

  &-text {
    margin: 0;
  }

  &-bar-container {
    flex: 1 1 auto;
    margin-left: 16px;
    margin-right: 16px;
  }

  &-bar {
    height: 4px;
    background-color: $blue-gray-light;
    width: 100%;
  }

  &-bar-fill {
    margin-top: -4px;
    height: 4px;
    background-color: $ed-blue;
    width: 50%;
  }

  &-stop {
    all: unset;
    cursor: pointer;
    height: 16px;
    width: 16px;
    background-image: url("/assets/icons/cross.svg");
    filter: invert(70%);
  }
}


</style>
