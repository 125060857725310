export default {
  dialogs: {
    "d1": {
      id: "d1",
      questionIds: ["d1-q1", "d1-q2", "d1-q3", "d1-q4", "d1-q5", "d1-q6", "d1-q7"],
      title: "Se présenter",
      name: "Dialog 1",
      successText: "C’était cool de bavarder avec toi.",
      successAudioSrc: "/assets/audio/d1-q1.mp3",
      illustrationUrl: '/assets/illustrations/dialog-01.svg',
      backgroundColor: '#2438E6',
      titleColor: '#FFFFFF',
      introText: "Hier übst du dich vorstellen",
    },
    "d2": {
      id: "d2",
      questionIds: ["d2-q1", "d2-q2", "d2-q3", "d2-q4", "d2-q5"],
      title: "Bienvenue dans ta nouvelle classe",
      name: "Dialog 2",
      successText: "C’était cool de bavarder avec toi.",
      successAudioSrc: "/assets/audio/d1-q1.mp3",
      illustrationUrl: '/assets/illustrations/dialog-02.svg',
      backgroundColor: '#FDE101',
      titleColor: '#222222',
      introText: "Hier übst du dich vorstellen",
    },
    "d3": {
      id: "d3",
      questionIds: ["d3-q1", "d3-q2",],
      title: "Donner son avis sur un tableau",
      name: "Dialog 3",
      successText: "C’était cool de bavarder avec toi.",
      successAudioSrc: "/assets/audio/d1-q1.mp3",
      illustrationUrl: '/assets/illustrations/dialog-03.svg',
      backgroundColor: '#F02666',
      titleColor: '#FFFFFF',
      introText: "Hier übst du Dialoge zu einem Museumsbesuch.",
    },
    "d4": {
      id: "d4",
      questionIds: ["d4-q1", "d4-q2", "d4-q3",],
      title: "Discuter d’un tableau",
      name: "Dialog 4",
      successText: "C’était cool de bavarder avec toi.",
      successAudioSrc: "/assets/audio/d1-q1.mp3",
      illustrationUrl: '/assets/illustrations/dialog-04.svg',
      backgroundColor: '#2438E6',
      titleColor: '#FFFFFF',
      introText: "Hier übst du Dialoge zu einem Museumsbesuch.",
    },
    "d5": {
      id: "d5",
      questionIds: ["d5-q1", "d5-q2",],
      title: "Visite au musée",
      name: "Dialog 5",
      successText: "C’était cool de bavarder avec toi.",
      successAudioSrc: "/assets/audio/d1-q1.mp3",
      illustrationUrl: '/assets/illustrations/dialog-05.svg',
      backgroundColor: '#F02666',
      titleColor: '#FFFFFF',
      introText: "Hier übst du Dialoge zu einem Museumsbesuch.",
    },
    "d6": {
      id: "d6",
      questionIds: ["d6-q1", "d6-q2", "d6-q3", "d6-q4",],
      title: "Remercier",
      name: "Dialog 6",
      successText: "C’était cool de bavarder avec toi.",
      successAudioSrc: "/assets/audio/d1-q1.mp3",
      illustrationUrl: '/assets/illustrations/dialog-06.svg',
      backgroundColor: '#FDE101',
      titleColor: '#222222',
      introText: "Hier übst du geläufige Ausdrücke.",
    },

  },
  questions: {
    "d1-q1": {
      id: "d1-q1",
      question: {
        text: "Salut, comment ça va?",
        audioSrc: "/assets/audio/d1-q1.mp3",
      },
      answers: [{
        id: "d1-q1-r1",
        uuid: 'a7f73920-7f4a-4837-9acd-01e723e15455',
        text: "Merci, ça va.",
        textAudioSrc: "/assets/audio/d1-q1-r1.mp3",
        response: "Ça me fait plaisir d’entendre ça!",
        responseAudioSrc: "/assets/audio/d1-q1-r1-f1.mp3",
      }, {
        id: "d1-q1-r2",
        uuid: '51d08dcb-bd9d-41fb-aa9f-a153dc7c2383',
        text: "Ça va très bien.",
        textAudioSrc: "/assets/audio/d1-q1-r2.mp3",
        response: "Ça me fait plaisir d’entendre ça!",
        responseAudioSrc: "/assets/audio/d1-q1-r2-f2.mp3",
      }]
    },
    "d1-q2": {
      id: "d1-q2",
      question: {
        text: "Comment tu t’appelles?",
        audioSrc: "/assets/audio/d1-q2.mp3",
      },
      answers: [{
        id: "d1-q2-r1",
        uuid: '852b1ec0-5100-48f7-b486-c7c6904ea0e5',
        text: "Je m’appelle Denise.",
        textAudioSrc: "/assets/audio/d1-q2-r1.mp3",
        response: "Moi, je m’appelle Skyler.",
        responseAudioSrc: "/assets/audio/d1-q2-r1-f1.mp3",
      }, {
        id: "d1-q2-r2",
        uuid: '255d88cb-15e4-4e88-9d04-87e243e126f3',
        text: "Mon nom est Philippe.",
        textAudioSrc: "/assets/audio/d1-q2-r2.mp3",
        response: "Moi, je m’appelle Skyler.",
        responseAudioSrc: "/assets/audio/d1-q2-r2-f2.mp3",
      }]
    },
    "d1-q3": {
      id: "d1-q3",
      question: {
        text: "Tu habites où?",
        audioSrc: "/assets/audio/d1-q3.mp3",
      },
      answers: [{
        id: "d1-q3-r1",
        uuid: '7b5e1452-39aa-4f65-a493-57638c905bfe',
        text: "J’habite à Fribourg.",
        textAudioSrc: "/assets/audio/d1-q3-r1.mp3",
        response: "Oh, c’est une belle ville!",
        responseAudioSrc: "/assets/audio/d1-q3-r1-f1.mp3",
      }, {
        id: "d1-q3-r2",
        uuid: '8460b330-02c7-45c6-a16a-b2de8c1ed69e',
        text: "J’habite en Suisse.",
        textAudioSrc: "/assets/audio/d1-q3-r2.mp3",
        response: "Cool, moi aussi!",
        responseAudioSrc: "/assets/audio/d1-q3-r2-f2.mp3",
      }]
    },
    "d1-q4": {
      id: "d1-q4",
      question: {
        text: "Tu as quel âge?",
        audioSrc: "/assets/audio/d1-q4.mp3",
      },
      answers: [{
        id: "d1-q4-r1",
        uuid: '50179c5f-38e6-4ef7-8206-3bd3603803bf',
        text: "J’ai 12 ans.",
        textAudioSrc: "/assets/audio/d1-q4-r1.mp3",
        response: "Comme moi!",
        responseAudioSrc: "/assets/audio/d1-q4-r1-f1.mp3",
      }, {
        id: "d1-q4-r2",
        uuid: 'b0e98071-97c3-4450-a76c-9fe7148ad271',
        text: "J’ai presque 13 ans.",
        textAudioSrc: "/assets/audio/d1-q4-r2.mp3",
        response: "Comme moi!",
        responseAudioSrc: "/assets/audio/d1-q4-r2-f2.mp3",
      }]
    },
    "d1-q5": {
      id: "d1-q5",
      question: {
        text: "Tu as des frères et sœurs?",
        audioSrc: "/assets/audio/d1-q5.mp3",
      },
      answers: [{
        id: "d1-q5-r1",
        uuid: '06464638-6858-42f6-8fa4-d83720183f92',
        text: "Oui, j’ai un frère. Il s’appelle Simon.",
        textAudioSrc: "/assets/audio/d1-q5-r1.mp3",
        response: "Super!",
        responseAudioSrc: "/assets/audio/d1-q5-r1-f1.mp3",
      }, {
        id: "d1-q5-r2",
        uuid: '69e46134-9381-48db-9d0e-027337e9040a',
        text: "Je n’ai pas de frères et sœurs.",
        textAudioSrc: "/assets/audio/d1-q5-r2.mp3",
        response: "Moi non plus.",
        responseAudioSrc: "/assets/audio/d1-q5-r2-f2.mp3",
      }]
    },
    "d1-q6": {
      id: "d1-q6",
      question: {
        text: "Tu parles quelles langues?",
        audioSrc: "/assets/audio/d1-q6.mp3",
      },
      answers: [{
        id: "d1-q6-r1",
        uuid: '12def639-653d-4539-9eae-6ec7851b7f68',
        text: "Je parle suisse-allemand.",
        textAudioSrc: "/assets/audio/d1-q6-r1.mp3",
        response: "Moi, je ne parle que français.",
        responseAudioSrc: "/assets/audio/d1-q6-r1-f1.mp3",
      }, {
        id: "d1-q6-r2",
        uuid: '84896fbe-ce6c-4da6-849c-033fd72727e6',
        text: "Je parle italien et aussi français.",
        textAudioSrc: "/assets/audio/d1-q6-r2.mp3",
        response: "Moi, je ne parle que français.",
        responseAudioSrc: "/assets/audio/d1-q6-r2-f2.mp3",
      }]
    },
    "d1-q7": {
      id: "d1-q7",
      question: {
        text: "Tu aimes faire quoi?",
        audioSrc: "/assets/audio/d1-q7.mp3",
      },
      answers: [{
        id: "d1-q7-r1",
        uuid: 'd2102411-c9bb-4880-b5cf-c6d83b1c2818',
        text: "J’aime aller à des festivals de musique.",
        textAudioSrc: "/assets/audio/d1-q7-r1.mp3",
        response: "Super. Allons-y!",
        responseAudioSrc: "/assets/audio/d1-q7-r1-f1.mp3",
      }, {
        id: "d1-q7-r2",
        uuid: '4ca7cbd1-c0f5-4868-9539-11ceb5682165',
        text: "J’aime plonger dans la mer.",
        textAudioSrc: "/assets/audio/d1-q7-r2.mp3",
        response: "Super. Allons-y!",
        responseAudioSrc: "/assets/audio/d1-q7-r2-f2.mp3",
      }]
    },
    "d2-q1": {
      id: "d2-q1",
      question: {
        text: "Est-ce que tu joues d’un instrument de musique?",
        audioSrc: "/assets/audio/d2-q1.mp3",
      },
      answers: [{
        id: "d2-q1-r1",
        uuid: '610d2201-f168-43d0-b204-9ba3d9d70e59',
        text: "Oui, je joue de la trompette.",
        textAudioSrc: "/assets/audio/d2-q1-r1.mp3",
        response: "J’adore cet instrument!",
        responseAudioSrc: "/assets/audio/d2-q1-r1-f1.mp3",
      }, {
        id: "d2-q1-r2",
        uuid: '5926e6c7-67d6-4375-8bad-fbd318d2e6db',
        text: "Non, je préfère le sport.",
        textAudioSrc: "/assets/audio/d2-q1-r2.mp3",
        response: "Moi aussi. Je joue au foot.",
        responseAudioSrc: "/assets/audio/d2-q1-r2-f2.mp3",
      }]
    },
    "d2-q2": {
      id: "d2-q2",
      question: {
        text: "Est-ce que tu fais du sport?",
        audioSrc: "/assets/audio/d2-q2.mp3",
      },
      answers: [{
        id: "d2-q2-r1",
        uuid: 'e2e76f09-b600-4baa-9981-483dc5c1e28b',
        text: "Oui, je joue au foot. Je m’entraîne avec mon équipe deux fois par semaine.",
        textAudioSrc: "/assets/audio/d2-q2-r1.mp3",
        response: "Je trouve ça génial!",
        responseAudioSrc: "/assets/audio/d2-q2-r1-f1.mp3",
      }, {
        id: "d2-q2-r2",
        uuid: '40eb8642-6e72-4931-9bf9-ae31d4e8c624',
        text: "Non, ça ne me plaît pas.",
        textAudioSrc: "/assets/audio/d2-q2-r2.mp3",
        response: "D’accord.",
        responseAudioSrc: "/assets/audio/d2-q2-r2-f2.mp3",
      }]
    },
    "d2-q3": {
      id: "d2-q3",
      question: {
        text: "Est-ce que tu as un animal domestique?",
        audioSrc: "/assets/audio/d2-q3.mp3",
      },
      answers: [{
        id: "d2-q3-r1",
        uuid: '1760136a-32a8-48fc-83ed-79c2c194713e',
        text: "Oui, j’ai un chien. Il s’appelle Vito.",
        textAudioSrc: "/assets/audio/d2-q3-r1.mp3",
        response: "C’est chouette!",
        responseAudioSrc: "/assets/audio/d2-q3-r1-f1.mp3",
      }, {
        id: "d2-q3-r2",
        uuid: '4add4fe4-fb59-4407-9ff8-19a78325bfa6',
        text: "Non, mais ma sœur a un cochon d’Inde.",
        textAudioSrc: "/assets/audio/d2-q3-r2.mp3",
        response: "C’est chouette!",
        responseAudioSrc: "/assets/audio/d2-q3-r2-f2.mp3",
      }]
    },
    "d2-q4": {
      id: "d2-q4",
      question: {
        text: "Quelle est ta matière préférée?",
        audioSrc: "/assets/audio/d2-q4.mp3",
      },
      answers: [{
        id: "d2-q4-r1",
        uuid: '4ec16b10-f410-40bc-a046-e3c7faafa1df',
        text: "J’aime la géo.",
        textAudioSrc: "/assets/audio/d2-q4-r1.mp3",
        response: "Moi, j’aime l’informatique et le dessin.",
        responseAudioSrc: "/assets/audio/d2-q4-r1-f1.mp3",
      }, {
        id: "d2-q4-r2",
        uuid: '6ecb68d9-0a6e-43f6-bee5-7f6061b7b090',
        text: "Je suis fort en maths, donc je préfère les maths.",
        textAudioSrc: "/assets/audio/d2-q4-r2.mp3",
        response: "Moi, j’aime l’informatique et le dessin.",
        responseAudioSrc: "/assets/audio/d2-q4-r2-f2.mp3",
      }]
    },
    "d2-q5": {
      id: "d2-q5",
      question: {
        text: "Est-ce que tu aimes jouer à des jeux sur ton téléphone mobile?",
        audioSrc: "/assets/audio/d2-q5.mp3",
      },
      answers: [{
        id: "d2-q5-r1",
        uuid: 'ce422562-5126-493a-9e68-fcf66037654c',
        text: "Oui, j’aime bien.",
        textAudioSrc: "/assets/audio/d2-q5-r1.mp3",
        response: "Moi, je n’aime pas trop.",
        responseAudioSrc: "/assets/audio/d2-q5-r1-f1.mp3",
      }, {
        id: "d2-q5-r2",
        uuid: '6cbe21e2-e4e3-4325-ac00-7944b7d4dd8f',
        text: "Non, je n’aime pas trop.",
        textAudioSrc: "/assets/audio/d2-q5-r2.mp3",
        response: "Moi non plus.",
        responseAudioSrc: "/assets/audio/d2-q5-r2-f2.mp3",
      }]
    },
    "d3-q1": {
      id: "d3-q1",
      question: {
        text: "Comment tu trouves ce tableau?",
        audioSrc: "/assets/audio/d3-q1.mp3",
      },
      answers: [{
        id: "d3-q1-r1",
        uuid: '55f7b80c-8fa7-4c84-a03c-4f5ac4300b5f',
        text: "C’est mon tableau préféré.",
        textAudioSrc: "/assets/audio/d3-q1-r1.mp3",
        response: "C’est aussi mon tableau préféré.",
        responseAudioSrc: "/assets/audio/d3-q1-r1-f1.mp3",
      }, {
        id: "d3-q1-r2",
        uuid: '6433f2bf-a973-43cf-bfdf-b3f8cd6c18d3',
        text: "J’aime moins ce tableau.",
        textAudioSrc: "/assets/audio/d3-q1-r2.mp3",
        response: "Moi aussi.",
        responseAudioSrc: "/assets/audio/d3-q1-r2-f2.mp3",
      }]
    },
    "d3-q2": {
      id: "d3-q2",
      question: {
        text: "Pourquoi?",
        audioSrc: "/assets/audio/d3-q2.mp3",
      },
      answers: [{
        id: "d3-q2-r1",
        uuid: 'c9a66aec-8d56-4e86-84d9-7befa84831c9',
        text: "Je trouve que ce tableau est bizarre.",
        textAudioSrc: "/assets/audio/d3-q2-r1.mp3",
        response: "Je suis d’accord.",
        responseAudioSrc: "/assets/audio/d3-q2-r1-f1.mp3",
      }, {
        id: "d3-q2-r2",
        uuid: 'a2ea3469-d904-48f5-94fe-16e7bb653369',
        text: "Pour moi, il est beau.",
        textAudioSrc: "/assets/audio/d3-q2-r2.mp3",
        response: "Ah, oui.  Il est très beau.",
        responseAudioSrc: "/assets/audio/d3-q2-r2-f2.mp3",
      }]
    },
    "d4-q1": {
      id: "d4-q1",
      question: {
        text: "Qu’est-ce qu’on voit sur le tableau?",
        audioSrc: "/assets/audio/d4-q1.mp3",
      },
      answers: [{
        id: "d4-q1-r1",
        uuid: 'c35706df-5a7a-45a8-ba5f-cc2f9edbdc2e',
        text: "C’est peut-être un café.",
        textAudioSrc: "/assets/audio/d4-q1-r1.mp3",
        response: "Oui, peut-être.",
        responseAudioSrc: "/assets/audio/d4-q1-r1-f1.mp3",
      }, {
        id: "d4-q1-r2",
        uuid: 'fa3fd581-3d23-458e-8e8e-afadb68bae37',
        text: "Ça fait penser à une plage.",
        textAudioSrc: "/assets/audio/d4-q1-r2.mp3",
        response: "C’est possible.",
        responseAudioSrc: "/assets/audio/d4-q1-r2-f2.mp3",
      }]
    },
    "d4-q2": {
      id: "d4-q2",
      question: {
        text: "Qu’est-ce qu’il y a au milieu?",
        audioSrc: "/assets/audio/d4-q2.mp3",
      },
      answers: [{
        id: "d4-q2-r1",
        uuid: '78bd5297-9d64-4e7f-af04-f56b10a0c91b',
        text: "Il y a une table.",
        textAudioSrc: "/assets/audio/d4-q2-r1.mp3",
        response: "Oui, une table ronde.",
        responseAudioSrc: "/assets/audio/d4-q2-r1-f1.mp3",
      }, {
        id: "d4-q2-r2",
        uuid: '74ace6f9-d60a-4702-ae2e-97c478314456',
        text: "On voit des verres.",
        textAudioSrc: "/assets/audio/d4-q2-r2.mp3",
        response: "Tout à fait.",
        responseAudioSrc: "/assets/audio/d4-q2-r2-f2.mp3",
      }]
    },
    "d4-q3": {
      id: "d4-q3",
      question: {
        text: "Et ce tableau, qu’est-ce qu’il montre?",
        audioSrc: "/assets/audio/d4-q3.mp3",
      },
      answers: [{
        id: "d4-q3-r1",
        uuid: 'f05f41ea-704b-4073-82d0-6fbf80ff0d3a',
        text: "Il s’agit d’une bibliothèque.",
        textAudioSrc: "/assets/audio/d4-q3-r1.mp3",
        response: "Moi, je vois autre chose.",
        responseAudioSrc: "/assets/audio/d4-q3-r1-f1.mp3",
      }, {
        id: "d4-q3-r2",
        uuid: '503e11d3-f7a3-4b04-97fa-ae9afaa5c8a2',
        text: "Il montre un parc.",
        textAudioSrc: "/assets/audio/d4-q3-r2.mp3",
        response: "Difficile à dire!",
        responseAudioSrc: "/assets/audio/d4-q3-r2-f2.mp3",
      }]
    },

    "d5-q1": {
      id: "d5-q1",
      question: {
        text: "Qu’est-ce que tu désires?",
        audioSrc: "/assets/audio/d5-q1.mp3",
      },
      answers: [{
        id: "d5-q1-r1",
        uuid: '7a692c4a-9d19-45db-858d-f36caae12b94',
        text: "Bonjour. Je voudrais un billet pour élève.",
        textAudioSrc: "/assets/audio/d5-q1-r1.mp3",
        response: "Le voilà. 15 francs, s’il te plaît.",
        responseAudioSrc: "/assets/audio/d5-q1-r1-f1.mp3",
      }, {
        id: "d5-q1-r2",
        uuid: '76b9d10c-4a1c-498e-95a7-1eec33ca1392',
        text: "J’aimerais deux billets pour l’exposition «Les animaux du roi».",
        textAudioSrc: "/assets/audio/d5-q1-r2.mp3",
        response: "Les voilà. Ça fait 30 francs.",
        responseAudioSrc: "/assets/audio/d5-q1-r2-f2.mp3",
      }]
    },
    "d5-q2": {
      id: "d5-q2",
      question: {
        text: "Est-ce que tu souhaites un audioguide?",
        audioSrc: "/assets/audio/d5-q2.mp3",
      },
      answers: [{
        id: "d5-q2-r1",
        uuid: '33583bc6-31d7-4ca0-b54a-f2575d13536b',
        text: "Oui, volontiers. Ça m’intéresse.",
        textAudioSrc: "/assets/audio/d5-q2-r1.mp3",
        response: "Très bien. ",
        responseAudioSrc: "/assets/audio/d5-q2-r1-f1.mp3",
      }, {
        id: "d5-q2-r2",
        uuid: '9ce41f20-7cc5-49ec-9e55-aed8e2452af6',
        text: "Non, merci. Je vais lire les informations.",
        textAudioSrc: "/assets/audio/d5-q2-r2.mp3",
        response: "Comme tu veux. Bonne visite!",
        responseAudioSrc: "/assets/audio/d5-q2-r2-f2.mp3",
      }]
    },

    "d6-q1": {
      id: "d6-q1",
      question: {
        text: "Merci beaucoup!",
        audioSrc: "/assets/audio/d6-q1.mp3",
      },
      answers: [{
        id: "d6-q1-r1",
        uuid: '899e9ff6-04d2-4262-8136-131839b5f232',
        text: "Il n’y a pas de quoi!",
        textAudioSrc: "/assets/audio/d6-q1-r1.mp3",
        response: "C’est gentil.",
        responseAudioSrc: "/assets/audio/d6-q1-r1-f1.mp3",
      }, {
        id: "d6-q1-r2",
        uuid: '5fe33cf2-2a19-40b3-ace7-954aaef6479d',
        text: "De rien!",
        textAudioSrc: "/assets/audio/d6-q1-r2.mp3",
        response: "C’est vraiment excellent.",
        responseAudioSrc: "/assets/audio/d6-q1-r2-f2.mp3",
      }]
    },
    "d6-q2": {
      id: "d6-q2",
      question: {
        text: "Tu as un moment?",
        audioSrc: "/assets/audio/d6-q2.mp3",
      },
      answers: [{
        id: "d6-q2-r1",
        uuid: '3940f282-a151-463d-b525-f1ed2a514fc9',
        text: "Je suis désolé/désolée, je suis très pressé/pressée.",
        textAudioSrc: "/assets/audio/d6-q2-r1.mp3",
        response: "Dommage!",
        responseAudioSrc: "/assets/audio/d6-q2-r1-f1.mp3",
      }, {
        id: "d6-q2-r2",
        uuid: '895c8e8c-05a8-41a8-ae6c-cd0387296b61',
        text: "Excuse-moi, je n’ai pas le temps",
        textAudioSrc: "/assets/audio/d6-q2-r2.mp3",
        response: "Ce n’est pas grave.",
        responseAudioSrc: "/assets/audio/d6-q2-r2-f2.mp3",
      }]
    },
    "d6-q3": {
      id: "d6-q3",
      question: {
        text: "Tu m’attends?",
        audioSrc: "/assets/audio/d6-q3.mp3",
      },
      answers: [{
        id: "d6-q3-r1",
        uuid: '4aab2288-f395-4891-8919-66ce90c5f8c6',
        text: "Oui, mais dépêche-toi un peu.",
        textAudioSrc: "/assets/audio/d6-q3-r1.mp3",
        response: "Oui, j’arrive.",
        responseAudioSrc: "/assets/audio/d6-q3-r1-f1.mp3",
      }, {
        id: "d6-q3-r2",
        uuid: 'bbcd3527-4be3-449e-9a7c-9bfb753e2c01',
        text: "Non. Je suis déjà en retard.",
        textAudioSrc: "/assets/audio/d6-q3-r2.mp3",
        response: "Tant pis!",
        responseAudioSrc: "/assets/audio/d6-q3-r2-f2.mp3",
      }]
    },
    "d6-q4": {
      id: "d6-q4",
      question: {
        text: "On va regarder le match ce soir?",
        audioSrc: "/assets/audio/d6-q4.mp3",
      },
      answers: [{
        id: "d6-q4-r1",
        uuid: '92b3a5a1-f124-4f51-86f0-ecc366615cdd',
        text: "D’accord. À 6 heures chez moi?!",
        textAudioSrc: "/assets/audio/d6-q4-r1.mp3",
        response: "Oui, comme toujours.",
        responseAudioSrc: "/assets/audio/d6-q4-r1-f1.mp3",
      }, {
        id: "d6-q4-r2",
        uuid: '75e5807e-96e3-4003-9930-3509be6335b7',
        text: "Mais oui! J’ai les billets.",
        textAudioSrc: "/assets/audio/d6-q4-r2.mp3",
        response: "Parfait!",
        responseAudioSrc: "/assets/audio/d6-q4-r2-f2.mp3",
      }]
    },
  },
};
