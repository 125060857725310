import axios from 'axios';
import * as log from 'loglevel';

export function trackEvent(category, action, name, value) {
  window._paq.push(['trackEvent', category, action, name, value]);

  if(window.php_user_info) {
    axios.post('/api.php?action=event', {
      category: category,
      action: action,
      name: name,
      value: value,
    })
      .then(response => {
        log.debug(response.data)
      })
      .catch(error => {
        log.warn(error);
        window.sessionError = true;
      });
  }
}
