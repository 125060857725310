import * as log from 'loglevel';
import axios from 'axios';

const {createStore} = require("vuex");

const store = createStore({
  state() {
    return {
      audioInput: null,
      audioInputDeviceId: '',
      audioInputDeviceLabel: '',
      audioFirstRecording: true,
      currentComponent: 'LandingPage',
      dialogId: "d1",

      showOnboarding: true,

      dialogStartTime: null,
      dialogNumberAttempts: 0,

      apiData: {
        dialogData: {}
      },
    }
  },
  getters: {
    audioAvailable(state) {
      return !!state.audioInput;
    },
    isDialogFinished(state) {
      return (dialogId) => {
        let finishedDialogIds = [];
        Object.entries(state.apiData.dialogData || {}).forEach(([dialogId, dialogData]) => {
          if (dialogData.finished) {
            finishedDialogIds.push(dialogId);
          }
        })
        return finishedDialogIds.indexOf(dialogId) >= 0;
      }
    }
  },
  mutations: {
    initializeAudio(state, payload) {
      state.audioInput= payload.audioInput;
      state.audioInputDeviceId = payload.deviceId;
      state.audioInputDeviceLabel = payload.deviceLabel;
    },
    setCurrentComponent(state, componentName) {
      state.currentComponent = componentName;
    },
    setDialogId(state, dialogId) {
      state.dialogId = dialogId;
    },
    setApiData(state, apiData) {
      if (!apiData) {
        apiData = {};
      }
      if (!apiData.dialogData) {
        apiData.dialogData = {};
      }
      state.apiData = apiData;
    },
    markDialogFinished(state, dialogId) {
      if (!state.apiData.dialogData[dialogId]) {
        state.apiData.dialogData[dialogId] = {};
      }
      state.apiData.dialogData[dialogId]['finished'] = (new Date()).toISOString();
    },
    markDialogStarted(state, dialogId) {
      if(!state.apiData.dialogData[dialogId]) {
        state.apiData.dialogData[dialogId] = {};
      }
      state.apiData.dialogData[dialogId]['started'] = (new Date()).toISOString();
    },
    setAudioFirstRecording(state, flag) {
      state.audioFirstRecording = flag;
    },
    setDialogStartTime(state, time) {
      state.dialogStartTime = time;
    },
    setDialogNumberAttempts(state, number) {
      state.dialogNumberAttempts = number;
    },
    incrementDialogNumberAttempts(state) {
      state.dialogNumberAttempts++;
    },
    setShowOnboarding(state, flag) {
      state.showOnboarding = flag;
    },
  },
  actions: {
    chooseDialog({commit}, dialogId) {
      commit('setDialogId', dialogId);
      commit('setCurrentComponent', 'MicrophoneCheck');
      commit('setDialogStartTime', (new Date()).getTime());
      commit('setDialogNumberAttempts', 0);
    },
    updateApi({state}) {
      if(window.php_user_info) {
        axios.post('/api.php?action=update', state.apiData)
          .then(response => {
            log.debug(response.data)
          })
          .catch(error => {
            log.warn(error);
            window.sessionError = true;
          });
      }
    },
    fetchApi({commit, state}) {
      if(window.php_user_info) {
        axios.get('/api.php?action=fetch')
          .then(response => {
            log.debug(response.data)
            if (response.data) {
              commit('setApiData', response.data);
            }
          })
          .catch(error => {
            log.warn(error);
            window.sessionError = true;
          });
      }
    },
    openMicrophone({getters, commit}) {
      if (!getters.audioAvailable) {
        return navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        }).then(function (stream) {
          log.debug("getUserMedia() success");
          log.debug(stream.getAudioTracks());
          log.debug(stream);

          const tracks = stream.getAudioTracks();
          const audioContext = new AudioContext();
          commit('initializeAudio', {
            audioInput: audioContext.createMediaStreamSource(stream),
            deviceId: tracks[0].id,
            deviceLabel: tracks[0].label
          });
        }).catch(function (err) {
          log.warn(err);
          return Promise.reject();
        });
      }
      else {
        return Promise.resolve();
      }
    },
  }
})

export default store;
