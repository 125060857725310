<template>
  <div
    class="app-component"
    v-if="dialogData"
  >
    <div class="heading-background"></div>
    <div class="title">
      <h1 class="app-header-title">
        Bravo! {{ dialogData.successText }}
      </h1>
      <div
        class="success-animation"
        ref="successAnimation"
      >
      </div>
    </div>
    <div class="explanation">
      <div class="app-button-section">
        <button
          class="app-continue-button"
          @click="allDialogs()"
        >
          Alle Dialoge anzeigen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import * as log from 'loglevel';

import { mapMutations } from 'vuex';
import data from '@/data';

export default {
  name: 'DialogSuccess',
  props: {
    id: { type: String, default: 'd1' }
  },
  data: function () {
    return {
      dialogData: null,
    }
  },
  computed: {
  },
  mounted() {
    log.debug("DialogSuccess mounted", this.id);

    this.dialogData = data.dialogs[this.id];

    this.$nextTick(() => {
      this.successAnimation = window.lottie.loadAnimation({
        container: this.$refs.successAnimation,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: '/assets/animations/avatar-success.json'
      });
    })
  },
  methods: {
    ...mapMutations(['setCurrentComponent']),
    allDialogs() {
      // trackEvent('Navigation', 'Alle Dialoge anzeigen', this.id);
      this.setCurrentComponent('DialogChooser');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "../assets/css/colors";
@import "../assets/css/variables";

$heading-height-small: 295px;
$heading-height-big: 345px;

.heading-background {
  @include color-header($heading-height-small, $heading-height-big);
}

.title {
  flex: 1 1 66%;
  width: 100%;
  padding: 48px;
  color: white;
  text-align: center;
  position: relative;

  .dialog-name {
    color: white;
  }

  .intro-animation {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 200px;
    text-align: center;
  }
}

.explanation {
  text-align: center;
  width: 100%;
  padding: 24px;
  margin-top: 100px;

  @media (min-width: 350px) {
    margin-top: 200px;
  }
}

.success-animation {
  position: absolute;
  margin-inline: auto;
  left: 0;
  right: 0;
  width: 220px;
  text-align: center;

  @media (min-width: 350px) {
    width: 240px;
  }

  @include desktop {
    width: 280px;
  }
}

</style>
