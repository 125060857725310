<template>
  <div
    class="app-container"
  >
    <component
      :is="name"
      :id="id"
    ></component>
  </div>
</template>

<script>

import * as log from 'loglevel';

import LandingPage from './LandingPage';
import MicrophoneCheck from './MicrophoneCheck';
import Dialog from './Dialog';
import MicrophoneProblem from './MicrophoneProblem';
import DialogIntro from './DialogIntro';
import DialogSuccess from './DialogSuccess';
import DialogChooser from './DialogChooser';
import Question from './Question';

export default {
  name: 'Main',
  components: {Dialog, DialogChooser, DialogIntro, DialogSuccess, LandingPage, MicrophoneCheck, MicrophoneProblem, Question},
  // eslint-disable-next-line vue/require-prop-types
  props: ['name', 'id'],
  data: function () {
    return {
    }
  },
  computed: {
  },
  mounted() {
    log.debug("Viewer mounted", this.name, this.id);
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "../assets/css/colors";


.app-component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}

.app-component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}
</style>
