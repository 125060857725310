import { createRouter, createWebHashHistory } from 'vue-router';
import LottieExample from './views/LottieExample.vue';
import Main from './views/Main';
import Viewer from './views/Viewer';

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    path: "/dialoge",
    name: "MainDialogChooser",
    component: Main,
  },
  {
    path: "/dialoge/:id",
    name: "MainDialog",
    component: Main,
  },
  {
    path: "/viewer/:name/:id",
    name: "ViewerWithId",
    props: true,
    component: Viewer,
  },
  {
    path: "/viewer/:name",
    name: "Viewer",
    props: true,
    component: Viewer,
  },
  {
    path: "/lottie",
    name: "LottieExample",
    component: LottieExample,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});

export default router;
