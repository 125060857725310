<template>
  <div class="lottie">
    <div
      class="lottie-animation"
      ref="animation"
    ></div>

    <div>
      <button @click="play([0, 1])">
        play wait
      </button>
      <button @click="play([2, 15], true)">
        play speak
      </button>
      <button @click="play([16, 58])">
        play understand
      </button>
      <button @click="play([59, 148])">
        play not understand
      </button>
    </div>
  </div>
</template>

<script>
import * as log from 'loglevel';

export default {
  name: 'LottieExample',
  mounted() {
    log.debug('LottieExample mounted');
    log.debug(this.$refs.animation);

    this.anim = window.lottie.loadAnimation({
      container: this.$refs.animation, // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/assets/animations/avatar-all-animations.json' // the path to the animation json
    });
  },
  methods: {
    play(frames, loop=false) {
      log.debug('play clicked');
      this.anim.loop = loop;
      this.anim.playSegments(frames, true);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.lottie {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center
}

.lottie-animation {
  width: 200px;
  height: 200px;
  margin: 32px;
}

button {
  margin: 8px;
}
</style>
