<template>
  <div
    class="app-container"
  >
    <transition
      name="component-fade"
      mode="out-in"
    >
      <component
        :is="currentComponent"
        :id="dialogId"
      ></component>
    </transition>
  </div>
</template>

<script>

import * as log from 'loglevel';

import { mapState } from 'vuex';
import LandingPage from './LandingPage';
import MicrophoneCheck from './MicrophoneCheck';
import Dialog from './Dialog';
import MicrophoneProblem from './MicrophoneProblem';
import DialogIntro from './DialogIntro';
import DialogSuccess from './DialogSuccess';
import DialogChooser from './DialogChooser';

export default {
  name: 'Main',
  components: {Dialog, DialogChooser, DialogIntro, DialogSuccess, LandingPage, MicrophoneCheck, MicrophoneProblem},
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState(['currentComponent', 'dialogId']),
  },
  created() {
    log.debug("Main created");
    log.debug(this.$route.name);
    if(this.$route.name === "MainDialogChooser") {
      this.$store.commit('setCurrentComponent', 'DialogChooser');
    }
  },
  mounted() {
    log.debug("Main mounted", this.component);
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "../assets/css/colors";

.app-component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .5s ease;
}

.app-component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}
</style>
