<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  mounted() {
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    window.addEventListener('load', this.setVh);
    window.addEventListener('resize', this.setVh);
  },
  methods: {
    setVh() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }
}
</script>

<style lang="scss">

@import "./assets/css/variables";
@import "./assets/css/colors";

body {
  background: #dddddd;
  color: $font-black;
}

.app-button-section {
  text-align: center;
}

.app-continue-button {
  display: inline-block;
  background: #71E388;
  border: 0;
  border-radius: 8px;
  padding-block: 12px;
  padding-inline: 30px;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin: 8px 0;
  text-align: center;
  color: $font-black;

  &:hover {
     cursor: pointer;
  }

  &__small {
    font-size: 16px;
    padding-block: 8px;
    line-height: 24px;
    padding-inline: 20px;
  }
}

.app-link {
  text-decoration: underline;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: $font-black;


  &:hover {
    cursor: pointer;
  }
}

.app-display-none {
  display: none !important;
}

.app-header-title {
  font-size: $title-size-small;
  line-height: $title-lineheight-small;
  margin-bottom: $margin-small;
  font-weight: 800;

  @include desktop {
  font-size: $title-size-big;
  line-height: $title-lineheight-big;
  }
}

.app-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: white;
  max-width: $max-content-width;
  margin-inline: auto;
}

.app-margin-top {
  padding-top: $margin-small;
  @include desktop {
    padding-top: $margin-big;
  }
}

.app-container {
  //max-width: 420px;
  //min-height: 600px;
  //max-height: 960px;

  @include min-height-100vh;
  margin: 0 auto;
  background: white;

}


</style>
