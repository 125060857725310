<template>
  <div class="app-component">
    <div class="back">
      <a
        class="skip-button app-link"
        @click="goBack()"
      >
        Zurück
      </a>
    </div>
    <div class="content">
      <h1>Dein Mikrofon funktioniert nicht.</h1>
      <ol class="solution">
        <li>Damit du diese App verwenden kannst, brauchst du eine funktionsfähiges Mikrofon.</li>
        <li>Du musst dein Mikrofon in deinem Browser für diese App freigeben.</li>
      </ol>
    </div>
    <div class="help">
      <a
        target="_blank"
        href="https://www.schulverlag.ch/de/produkte/support/meine-lehrmittel/sprachassistent"
        class="skip-button app-link"
      >
        Brauchst du Hilfe?
      </a>
    </div>
  </div>
</template>

<script>
import * as log from 'loglevel';

import { mapMutations } from 'vuex';

export default {
  name: 'MicrophoneProblem',
  data: function () {
    return {
    }
  },
  computed: {
  },
  mounted() {
    log.debug("MicrophoneProblem mounted");
  },
  methods: {
    ...mapMutations(["setCurrentComponent"]),
    goBack() {
      this.setCurrentComponent("DialogChooser");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "../assets/css/colors";
@import "../assets/css/variables";

.app-component {
  background: white;
  padding: $margin-small;
  text-align: center;
  @include min-height-100vh();
  display: flex;

  h1 {
    @include margin-bottom;
  }
}

.solution {
  list-style: none;
  counter-reset: li;
  padding-left: 0;
  flex-grow: 1;

  li {
    counter-increment: li;
    line-height: 150%;
    font-size: 20px;
    margin-bottom: 50px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &::before {
      content: counter(li);
      color: #ffffff;
      height: 46px;
      width: 46px;
      background-color: #000;
      border-radius: 23px;
      display: block;
      font-size: 30px;
      text-align: center;
      line-height: 46px;
      margin-inline: auto;
      margin-bottom: 11px;
    }
  }
}

.content {
  flex: 1 1 auto;
}

.help {
  flex: 0 0 auto;
}

.back {
  flex: 0 0 auto;
  margin-bottom: 32px;
}
</style>
