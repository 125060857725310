import * as log from 'loglevel';

import { createApp } from 'vue';
import router from './router';
import store from './store';
import VueMatomo from 'vue-matomo';
import App from './App.vue';

import './assets/css/modern-normalize.css';

if(window.location.hostname === 'localhost') {
  log.enableAll();
  // log.setLevel('warn');
} else {
  log.setLevel('warn');
}

log.debug(document.location.hostname);

let matomoOptions = {
  host: 'https://sprachwelt1digital.matomo.cloud',
  siteId: 6,
}

if (document.location.hostname === 'sprachassistent.edsolution.swiss') {
  matomoOptions = {
    host: 'https://analytics.edsolution.ch',
    siteId: 2,
  }
} else if (document.location.hostname === 'sprachassistent.iterativ.ch') {
  log.enableAll();
  matomoOptions = {
    host: 'https://myskillbox.matomo.cloud',
    siteId: 8,
  }
}

createApp(App)
  .use(router)
  .use(store)
  .use(VueMatomo, matomoOptions)
  .mount('#app');
