<template>
  <div
    class="app-component"
    v-if="dialogs.length && showPage"
  >
    <div
      class="dialog"
      v-for="dialog in dialogs"
      :key="dialog.id"
      @click="startDialog(dialog.id)"
    >
      <div
        class="illustration"
        :style="{'background-color': dialog.backgroundColor, 'border-color': dialog.backgroundColor}"
      >
        <div
          class="illustration-image"
          :style="{'background-image': `url(${dialog.illustrationUrl})`}"
        ></div>
      </div>
      <div class="text">
        <div class="name">
          {{ dialog.name }}
        </div>
        <div class="title">
          {{ dialog.title }}
        </div>
        <div
          class="finished-icon"
          v-show="isDialogFinished(dialog.id)"
        >
          <img
            src="/assets/icons/thumb-up.svg"
            alt="finished"
          />
          <span class="finished-icon-text">Bravo</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as log from 'loglevel';

import data from '@/data';
import { mapActions, mapGetters } from 'vuex';
import { trackEvent } from '@/matomoClient';

export default {
  name: 'DialogSuccess',
  data: function () {
    return {
      dialogs: [],
      showPage: true,
    }
  },
  computed: {
    ...mapGetters(['isDialogFinished']),
  },
  created() {
    log.debug("DialogChooser created");
    if(window.php_app && !window.php_disable_auth && (window.php_user_info === null || window.sessionError)) {
      log.debug("Redirecting to login page");
      window.location.replace('/auth.php');
      this.showPage = false;
    } else {
      log.debug("Still logged in")
    }
  },
  mounted() {
    log.debug("DialogChooser mounted", window.php_user_info);
    this.dialogs = Object.values(data.dialogs);
    trackEvent('Navigation', 'DialogChooser opened');
    this.fetchApi();
  },
  methods: {
    ...mapActions(['chooseDialog', 'fetchApi']),
    startDialog(dialogId) {
      trackEvent('Navigation', 'DialogChooser dialog selected', dialogId);
      this.chooseDialog(dialogId);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "../assets/css/colors";
@import "../assets/css/variables";

.app-component {
  padding: 24px;
  max-width: $max-content-width;
  margin-inline: auto;
}

.dialog {
  margin-top: 8px;
  margin-bottom: 8px;
  // padding: 16px;
  border: 1px solid $gray-border;
  border-radius: 16px;
  width: 100%;
  display: flex;
  min-height: 128px;
  cursor: pointer;

  .illustration {
    flex: 0 0 128px;
    background-color: $ed-blue;
    border-radius: 16px 0 0 16px;
    display: flex;
    justify-content: center;

    .illustration-image {
      width: 80%;
      height: 100%;
      background-size: contain;
      background-position: top 16px center;
      background-repeat: no-repeat;
      //background-image: url("/assets/illustrations/d2-title.svg");
    }
  }

  .text {
    flex: 1 1 auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.name {
  color: #767676;
}

.title {
  font-size: 16px;
  font-weight: 700;
  flex: 1 1 auto;
  margin-top: 8px;
  line-height: 1.2;
}

.finished-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.finished-icon-text {
  margin-left: 8px;
}

.start-button {
  border: 0;
  padding: 0;
  background-color: transparent !important;
  box-shadow: none !important;
  &:focus, &:hover {
      outline: 0;
      background-color: transparent !important;
  }
  height: 50px;
  width: 50px;
  background-image: url("/assets/icons/play-icon.svg");
  cursor: pointer;
}

.trophy {
  width: 55px;
  height: 55px;
  background-image: url("/assets/icons/trophy.svg");
}

.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
