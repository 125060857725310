<template>
  <div
    class="app-component"
    v-if="dialogData"
  >
    <Question
      :id="dialogData.questionIds[questionIdIndex]"
      :current-index="questionIdIndex"
      :number-of-questions="numberOfQuestions"
      @nextQuestion="nextQuestion()"
    ></Question>
  </div>
</template>

<script>

import * as log from 'loglevel';

import data from '@/data';
import Question from './Question';
import { mapActions, mapMutations, mapState } from 'vuex';
import { trackEvent } from '@/matomoClient';

export default {
  name: 'Dialog',
  components: {Question},
  props: {
    id: { type: String, default: 'd1' }
  },
  data: function () {
    return {
      questionIdIndex: 0,
      numberOfQuestions: 0,
      dialogData: null,
    }
  },
  computed: {
    ...mapState(['dialogStartTime', 'dialogNumberAttempts',]),
  },
  mounted() {
    log.debug("Dialog mounted");
    this.dialogData = data.dialogs[this.id];
    this.numberOfQuestions = this.dialogData.questionIds.length;
  },
  methods: {
    ...mapMutations(['setCurrentComponent', 'markDialogFinished']),
    ...mapActions(['updateApi']),
    nextQuestion() {
      log.debug('handle "nextQuestion" in dialog')
      this.questionIdIndex += 1;
      log.debug("questionIndex", this.questionIdIndex);

      if (this.questionIdIndex >= this.dialogData.questionIds.length) {
        this.markDialogFinished(this.id);
        this.updateApi();
        this.setCurrentComponent('DialogSuccess');

        const dialogRunTime = (new Date()).getTime() - this.dialogStartTime;
        const dialogRunTimeSeconds = Math.round(dialogRunTime / 1000);
        log.info('dialogRunTimeSeconds', dialogRunTimeSeconds);
        log.info('dialogNumberAttempts', this.dialogNumberAttempts);
        trackEvent('Dialog', 'Dialog erfolgreich - Zeit', this.id, dialogRunTimeSeconds);
        trackEvent('Dialog', 'Dialog erfolgreich - Versuche', this.id, this.dialogNumberAttempts);
        trackEvent('GoalTracking', `Dialog ${this.id} beendet`, this.id, this.dialogNumberAttempts);
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "../assets/css/colors";

</style>
