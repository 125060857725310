<template>
  <div
    class="app-component"
    v-if="dialogData"
  >
    <div
      class="heading-background"
      :style="{'background-color': dialogData.backgroundColor}"
    ></div>
    <div class="page">
      <div
        class="navigation"
        @click="goBack()"
      >
        <div
          class="back-button"
          :class="[dialogData.titleColor.toLowerCase() === '#ffffff' ? 'back-button-white' : '']"
        >
          <img
            src="/assets/icons/cross.svg"
            alt="back"
          >
        </div>
      </div>
      <div
        class="title"
        :style="{color: dialogData.titleColor}"
      >
        <div class="title-text">
          <div class="dialog-name">
            {{ dialogData.name }}
          </div>
          <h1 class="dialog-title">
            {{ dialogData.title }}
          </h1>
        </div>
        <div class="title-illustration">
          <img :src="illustrationName" />
        </div>
      </div>

      <div class="explanation">
        <div
          class="finished-icon"
          v-show="isDialogFinished(dialogData.id)"
        >
          <img
            src="/assets/icons/thumb-up.svg"
            alt="finished"
          />
          <span class="finished-icon-text">Bravo</span>
        </div>
        <h2 class="intro-text">
          {{ dialogData.introText }}
        </h2>
        <div class="volume">
          <img
            src="/assets/icons/volume.svg"
            alt="volume icon"
            class="volume-icon"
          >
          <span class="volume-text">Dreh die Lautstärke auf</span>
        </div>
      </div>

      <div class="buttons">
        <div class="app-button-section">
          <button
            class="app-continue-button"
            @click="startDialog()"
            style="width: 100%"
          >
            Dialog starten
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import * as log from 'loglevel';

import { mapActions, mapGetters, mapMutations } from 'vuex';
import data from '@/data';
import { trackEvent } from '@/matomoClient';

export default {
  name: 'DialogIntro',
  props: {
    id: { type: String, default: 'd1' }
  },
  data: function () {
    return {
      dialogData: null,
    }
  },
  computed: {
    ...mapGetters(['isDialogFinished']),
    illustrationName() {
      if (this.dialogData) {
        return this.dialogData.illustrationUrl;
      }
      return '';
    },
  },
  mounted() {
    log.debug("DialogIntro mounted", this.id);
    this.dialogData = data.dialogs[this.id];
  },
  methods: {
    ...mapMutations(['setCurrentComponent', 'markDialogStarted']),
    ...mapActions(['updateApi']),
    startDialog() {
      if (window.sessionError) {
        this.setCurrentComponent('DialogChooser');
      } else {
        this.markDialogStarted(this.id);
        this.updateApi();
        trackEvent('Dialog', 'Dialog starten', this.id);
        trackEvent('GoalTracking', `Dialog ${this.id} gestartet`);
        this.setCurrentComponent('Dialog');
      }
    },
    goBack() {
      this.setCurrentComponent('DialogChooser');
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "../assets/css/colors";
@import "../assets/css/variables";

$heading-height-small: 244px;
$heading-height-big: 244px;

.navigation {
  height: 16px;
}

.heading-background {
  @include color-header($heading-height-small, $heading-height-big);
}


.page {
  flex: 1 1 100%;
  width: 100%;
  color: white;
  text-align: center;
  position: relative;
  padding-inline: 0;
  min-height: 500px;
  display: flex;
  flex-direction: column;

  margin-top: 16px;

  @media (min-width: 350px) {
    padding-inline: 20px;
  }
}

.back-button {
  color: white;
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin-bottom: 16px;
  float: right;
}

.back-button-white {
  filter: invert(100%);
}

.intro-text {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.4;
}

.explanation {
  flex: 1 1 60%;
  text-align: left;
  width: 100%;
  color: $font-black;
  font-size: 16px;
}


.finished-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}

.finished-icon-text {
  margin-left: 8px;
  color: #767676;
}

.volume {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &-text {
    margin-left: 8px;
  }
}

.title {
  display: flex;
  flex-direction: row;
  min-height: $heading-height-small - 32px;
  color: white;

  .dialog-name {
    margin-bottom: 8px;
  }

  .title-illustration {
    flex: 1 0 auto;
    margin-top: 16px;
    margin-left: 16px;

    img {
      height: 210px;
    }
  }

  .title-text {
    text-align: left;
    flex: 1 1 auto;
    line-height: 1.3;
  }
}

</style>
