<template>
  <div class="app-component">
    <div
      class="main"
      v-show="showPageContent"
    >
      <div class="back">
        <a
          class="skip-button app-link"
          @click="goBack()"
        >
          Zurück
        </a>
      </div>
      <div class="content">
        <h1>Mikrofon freigeben</h1>

        <div class="explanation">
          Damit wir dich verstehen können, musst du dein Mikrofon freigeben.
        </div>
        <div class="app-button-section">
          <button
            class="app-continue-button"
            @click="localOpenMicrophone()"
          >
            Freigabe anfragen
          </button>
        </div>
      </div>

      <div class="help">
        <a
          target="_blank"
          href="https://www.schulverlag.ch/de/produkte/support/meine-lehrmittel/sprachassistent"
          class="skip-button app-link"
        >
          Brauchst du Hilfe?
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import * as log from 'loglevel';

import { mapActions, mapMutations } from 'vuex';
import { trackEvent } from '@/matomoClient';

export default {
  name: 'MicrophoneCheck',
  data: function () {
    return {
      showPageContent: true,
    }
  },
  computed: {
  },
  created() {
    log.debug("MicrophoneCheck created");
    if(window.sessionStorage.getItem('microphoneChecked')) {
      this.showPageContent = false;
      this.localOpenMicrophone();
    }
  },
  mounted() {
    log.debug("MicrophoneCheck mounted");
  },
  methods: {
    ...mapActions(['openMicrophone']),
    ...mapMutations(["setCurrentComponent"]),
    localOpenMicrophone() {
      // trackEvent('Navigation', 'Mikrophonfreigabe anfragen');
      this.openMicrophone().then(() => {
        window.sessionStorage.setItem('microphoneChecked', JSON.stringify(true));
        this.setCurrentComponent("DialogIntro");
      }).catch(() => {
        log.warn('MicrophoneCheck: openMicrophone failed');
        trackEvent('Error', 'MicrophoneProblem');
        this.setCurrentComponent("MicrophoneProblem")
      });
    },
    goBack() {
      this.setCurrentComponent("DialogChooser");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "../assets/css/colors";
@import "../assets/css/variables";

.main {
  @include min-height-100vh();
  padding: $margin-small;
  text-align: center;
  color: $font-black;

  display: flex;
  flex-direction: column;
  justify-content: space-between;


  h1 {
    @include margin-bottom;
  }
}

.explanation {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: $margin-small;

  @include margin-bottom;
}

.content {
  flex: 1 1 auto;
}

.help {
  flex: 0 0 auto;
}

.back {
  flex: 0 0 auto;
  margin-bottom: 32px;
}
</style>
