<template>
  <div class="landingpage">
    <header class="header">
      <section class="flex-left">
        <div class="header-icon">
          <img
            class="header-icon__img"
            src="/assets/landingpage/schulverlag_plus.svg"
            alt="Schulverlag Plus Logo"
          >
        </div>

        <div class="header-title">
          <h1 class="title">
            Salut und willkommen beim Sprachassistenten des Schulverlag plus!
          </h1>
        </div>

        <div class="header-button-desktop">
          <button
            class="app-continue-button"
            @click="start()"
          >
            Los geht's!
          </button>
        </div>
      </section>

      <section class="flex-right">
        <div class="header-character">
          <img
            class="header-character__img"
            src="/assets/landingpage/character-demo.svg"
            alt="Character"
          >
        </div>

        <div class="header-button-mobile">
          <button
            class="app-continue-button header-button-mobile__button"
            @click="start()"
          >
            Los geht's!
          </button>
        </div>
      </section>
    </header>

    <main class="main-container">
      <div class="main">
        <section class="flex-left">
          <div>
            <h2 class="title-h2">
              Für Schülerinnen und Schüler
            </h2>

            <p class="main-paragraph">
              Schön, bist du hier! Mit dem Sprachassistenten kannst du in deinem Tempo Unterhaltungen auf Französisch
              trainieren – ganz bequem von deinem Tablet, Laptop oder Smartphone. Hierzu haben wir vorerst in dieser
              Demoversion sechs Dialoge vorbereitet, weitere folgen.
            </p>

            <p class="main-paragraph">
              Wir freuen uns schon sehr auf viel Geplauder mit dir!
            </p>

            <button
              class="app-continue-button app-continue-button__small"
              @click="start()"
            >
              Los geht's!
            </button>
          </div>

          <div class="next-paragraph">
            <h2 class="title-h2">
              Für Lehrpersonen
            </h2>

            <p class="main-paragraph">
              Haben Sie Anmerkungen oder Fragen zum Sprachassistenten? Dann kontaktieren Sie den Schulverlag plus
              unter:<br>
              info@schulverlag.ch <br>oder <br>Telefon +41 58 268 14 14
            </p>
          </div>
        </section>

        <section class="flex-right">
          <div class="iphone-container">
            <img
              class="iphone-image"
              src="/assets/landingpage/sprachassistent-iphone-optimized.png"
              alt="App Image"
            >
          </div>
        </section>
      </div>
    </main>

    <footer class="footer">
      <ul class="footer-links">
        <li>
          <a
            class="footer__link"
            href="https://www.schulverlag.ch/de/agbdatenschutz/"
            target="_blank"
          >
            Datenschutz
          </a>
        </li>
        <li>
          <a
            class="footer__link"
            href="https://www.schulverlag.ch/de/kontakt/"
            target="_blank"
          >
            Kontakt
          </a>
        </li>
        <li>
          <a
            class="footer__link"
            href="https://www.schulverlag.ch/de/produkte/support/meine-lehrmittel/sprachassistent"
            target="_blank"
          >
            FAQ
          </a>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
import * as log from 'loglevel';

import { mapMutations } from 'vuex';
import { trackEvent } from '@/matomoClient';

export default {
  name: 'LandingPage',
  data: function () {
    return {
    }
  },
  computed: {
  },
  mounted() {
    log.debug("LandingPage mounted", window.php_app, window.php_user_info);
    trackEvent('Navigation', 'Landingpage opened');

    this.introAnimation = window.lottie.loadAnimation({
      container: this.$refs.introAnimation,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: '/assets/animations/avatar-intro.json'
    });
  },
  methods: {
    ...mapMutations(['setCurrentComponent']),
    start () {
      trackEvent('Navigation', 'Landingpage start clicked');
      this.$router.push({ name: 'MainDialogChooser' });
      this.setCurrentComponent('DialogChooser');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "../assets/css/colors";
@import "../assets/css/variables";

$container-width: 1024px;

.main-paragraph {
  line-height: 1.6;
}

.title {
  font-size: 24px;
  font-weight: 800;
  line-height: 1.4;


  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 1.2;
  }

  @media (min-width: 1024px) {
    font-size: 40px;
    line-height: 1.2;
  }
}


.title-h2 {
  font-size: 24px;
  font-weight: 800;
  line-height: 1.4;


  @media (min-width: 768px) {
    font-size: 26px;
    line-height: 1.2;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
    line-height: 1.2;
  }
}

.landingpage {
  position: relative;
}

.flex-left {
  flex: 1 1 auto;

  @include desktop() {
    margin-right: 32px;
  }
}

.flex-right {
  flex: 0 0 360px;
}

.header {
  max-width: $container-width;
  margin: 0 auto;

  text-align: center;
  padding: 16px 32px;

  .header-icon {
    &__img {
      width: 100px;
    }
  }

  .app-header-title {
    margin: 32px;
  }

  .header-character {
    margin-bottom: 16px;
    margin-top: 32px;

    &__img {
      width: 260px;
    }
  }

  .header-button-desktop {
    display: none;
  }

  .header-button-mobile {
    margin-block: $margin-small;

    @include desktop {
      padding-inline: $margin-big;
      margin-block: $margin-big;
    }

    &__button {
      width: 100%;
    }
  }

  @include desktop {
    text-align: left;
    display: flex;
    align-items: flex-start;
    padding-top: 32px;

    .header-button-mobile {
      display: none;
    }

    .header-button-desktop {
      display: block;
      margin-top: 32px;
    }

    .header-icon {
      &__img {
        width: 120px;
      }
    }

    .header-title {
      margin-top: 32px;
    }

    .header-character {
      margin-top: 0;
      text-align: center;

      &__img {
        width: 340px;
      }
    }
  }
}

.main-container {
  background: #ECEEF9;
  border-bottom: 1px solid $gray-border;

  @include desktop() {
    margin-top: -64px;
  }
}

.main {
  text-align: left;
  padding: 16px 32px;
  max-width: $container-width;
  margin: 0 auto;

  .iphone-container {
    text-align: center;
    .iphone-image {
      margin-top: 48px;
      max-width: 100%;
      width: 285px;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
      border-radius: 30px;
    }
  }

  .next-paragraph {
    margin-top: 32px;
  }

  @include desktop {
    display: flex;
    align-items: flex-start;
    padding-bottom: 64px;
    padding-top: 92px;

    .iphone-container {
      margin-top: -16px;
    }

    .next-paragraph {
      margin-top: 64px;
    }
  }
}

.footer {
  padding: 8px 32px;
  background: white;
  width: 100%;
  max-width: $container-width;
  margin: 0 auto;
  position: absolute;
  bottom: 0;

  border-top: 1px solid $gray-border;

  &-links {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }

  &__link {
    text-decoration: underline;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-right: 24px;


    &:hover {
      cursor: pointer;
    }
  }

  @include desktop() {
    position: relative;
    border-top: none;

    &-links {
      justify-content: left;
    }
  }
}

</style>
